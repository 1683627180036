<template>
  <div>
    <default-app-bar :showCancel="false">
      <template v-slot:menu>
        <v-app-bar-nav-icon
          color="primary"
          @click.stop="toogleDrawer"
        ></v-app-bar-nav-icon>
      </template>

      <template v-slot:title>
        <v-toolbar-title class="px-0">
           <img
            src="/assets/images/brand/primary-icon-logo.svg"
            class="d-lg-none d-block"
            width="35"
          />
          <img
            src="/assets/images/brand/primary-horizontal-logo.svg"
            class="d-none d-lg-block"
            width="180"
          />
        </v-toolbar-title>
        <span class="d-none d-md-block ml-1 primary--text text-button" v-if="localVersion"
          >V{{ localVersion }}</span
        >
      </template>

      <template v-slot:actions>
        <v-row>
          <v-col cols="12" class="pl-2 d-flex flex-row align-center">
            <div
              class="d-flex flex-row align-center me-2"
              v-if="!$store.getters.mobile"
            >
              <v-btn text small class="grey--text me-2">{{
                $t("price_list")
              }}</v-btn>
              <ng-select-options
                class="price_list"
                v-if="$pos && $pos.priceLists"
                v-model="currentPriceList"
                :items="$pos.priceLists"
                @change="changePriceList"
                :clearable="false"
                :flat="false"
                :filled="false"
                dense
                :outlined="true"
                hide-details
                :full-width="false"
              ></ng-select-options>
            </div>

            <div
              class="d-flex flex-row grey lighten-4 rounded-pill py-1 px-2 me-2"
            >
              <v-btn
                fab
                :x-small="$store.getters.mobile ? true : false"
                :small="!$store.getters.mobile ? true : false"
                class="me-2 my-auto"
                :title="$t('pending_orders')"
                :key="$store.getters.orderCreated"
                @click="openModalCartPendingOrders()"
              >
                <v-badge
                  color="primary darken-2"
                  :content="'' + totalPendingOrders"
                  overlap
                >
                  <v-icon color="grey">mdi-receipt</v-icon>
                </v-badge>
              </v-btn>

              <v-btn
                fab
                :x-small="$store.getters.mobile ? true : false"
                :small="!$store.getters.mobile ? true : false"
                class="me-2 my-auto"
                :title="$t('tables')"
                @click="modalTables = true"
                v-if="$store.getters.getGeneralValue('gen_sales_manage_tables')"
              >
                <v-icon color="grey">mdi-table-furniture</v-icon>
              </v-btn>
              
              <v-btn
                fab
                :x-small="$store.getters.mobile ? true : false"
                :small="!$store.getters.mobile ? true : false"
                class="my-auto"
                @click="updateInfo()"
                :title="$t('syncro')"
              >
                <v-icon color="grey">mdi-cloud-sync-outline</v-icon>
              </v-btn>
              <v-btn
                fab
                :x-small="$store.getters.mobile ? true : false"
                :small="!$store.getters.mobile ? true : false"
                class="my-auto"
                @click="updateInfo()"
                :title="$t('tutorials')"
              >
                <v-icon color="grey">mdi-play-circle-outline</v-icon>
              </v-btn>
            </div>

            <div
              class="d-flex flex-row grey lighten-4 rounded-pill pr-1 py-1 me-2" v-if="$store.getters.mobile"
            >
            <v-btn icon small :color="Math.ceil($pos.getCurrentOrder().getTotalItems()) > 0 ? 'green darken-2' : ''">
  <v-badge
  color="green darken-2"
                      :x-small="$store.getters.mobile ? true : false"
                      :small="!$store.getters.mobile ? true : false"
                      v-if="Math.ceil($pos.getCurrentOrder().getTotalItems()) > 0"
                      :content="
                        Math.ceil($pos.getCurrentOrder().getTotalItems())
                      "
                      overlap
                      :key="$store.getters.orderChanged"
  >
    <v-icon>mdi-basket</v-icon>
  </v-badge>
  <v-icon v-else>mdi-basket</v-icon>
</v-btn>
                    

            </div>

            <div
              class="d-flex flex-row grey lighten-4 rounded-pill pr-1 py-1 me-2"
              account="chat"
            >
              <SupportChat />
            </div>

            <div class="balance-contrast pr-2 rounded-pill">
              <v-menu
                bottom
                left
                offset-y
                origin="top right"
                transition="scale-transition"
                class="account-menu"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    dark
                    icon
                    color="transparent"
                    elevation="0"
                    id="btn-menu-profile"
                  >
                    <v-avatar size="35" class="primary">
                      <v-icon color="white" size="18">mdi-account</v-icon>
                    </v-avatar>
                    <v-icon color="grey">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list style="width: 350px !important">
                  <v-list-item
                    v-if="$store.getters.user"
                    class="d-flex justify-center align-center"
                  >
                    <div class="account-header text-center">
                      <v-list-item two-line class="pa-0 red mx-auto">
                        <div>
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-avatar
                                v-if="
                                  $store.getters.company &&
                                  $store.getters.company.logo
                                "
                              >
                                <img
                                  :src="
                                    $http.getBaseUrlPublicFiles() +
                                    $store.getters.company.logo
                                  "
                                  :alt="$store.getters.company.name"
                                />
                              </v-list-item-avatar>

                              <v-list-item-content class="text-left">
                                <v-list-item-title
                                  v-if="$store.getters.company"
                                  class="pb-0 primary--text"
                                  >{{ $store.getters.company.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  v-if="$store.getters.user"
                                  >{{
                                    $store.getters.user.name
                                  }}</v-list-item-subtitle
                                >

                                <div class="d-flex align-center">
                                  <span
                                    v-if="
                                      $store.getters.user.rol &&
                                      $store.getters.user.rol.name
                                    "
                                    class="me-1"
                                    ><v-chip small>{{
                                      $store.getters.user.rol.name
                                    }}</v-chip></span
                                  >
                                  <span
                                    v-if="app_version"
                                    class="caption grey--text"
                                    >v{{ app_version }}</span
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-list-item>
                    </div>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item @click="openModalCartPendingOrders()">
                    <v-list-item-icon
                      class="orange lighten-5 py-2 px-2 rounded"
                    >
                      <v-badge
                        color="orange darken-2"
                        :content="'' + totalPendingOrders"
                        overlap
                      >
                        <v-icon color="orange darken-1">mdi-receipt</v-icon>
                      </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="orange--text">
                        {{ $t("pending_orders") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("pending_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="modalTables = true"
                    v-if="
                      $store.getters.getGeneralValue('gen_sales_manage_tables')
                    "
                  >
                    <v-list-item-icon class="deep-purple py-2 px-2 rounded">
                      <v-icon color="white">mdi-table-furniture</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="deep-purple--text">
                        {{ $t("tables") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("tables_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="updateInfo()">
                    <v-list-item-icon class="primary py-2 px-2 rounded">
                      <v-icon color="white">mdi-cloud-sync-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">
                        {{ $t("syncro") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("syncro_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    id="menu-item-logout"
                    class="text-center grey--text"
                  >
                    <v-btn
                      @click="$store.dispatch('logoutAuth')"
                      elevation="0"
                      block
                      outlined
                      color="primary"
                      class="rounded-lg"
                      >{{ $t("logout") }}</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </template>
    </default-app-bar>

    <v-row
      v-if="installed && user"
      tabindex="0"
      class="ma-0 pa-0"
      :class="
        $store.getters.mobile ? 'cart_mobile mobile-height' : 'desktop-height'
      "
    >
      <!-- gen_sales_manage_screens 
    {{ $store.getters.getGeneralValue('gen_sales_manage_screens') }}
     -->
      <template v-if="$store.getters.mobile">
        <v-col cols="12" v-if="!showCartMobile" class="pa-0 mb-0">
          <v-toolbar dark color="info" class="fixed-bar" height="26">
            <v-toolbar-title class=""
              >{{ $t("total") }}
              {{
                $filters.currency(
                  $pos.getCurrentOrder().getPriceTotalWithAdds(),
                  0
                )
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$store.getters.mobile"
              :x-small="$store.getters.mobile ? true : false"
              fab
              color="grey lighten-5"
              @click="viewCartMobile(true)"
            >
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col cols="12" v-if="showCartMobile" class="pa-0">
          <v-toolbar dark color="blue" dense class="fixed-bar">
            <v-btn
              fab
              small
              color="white"
              @click="viewCartMobile(false)"
              class="me-2"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("go_back_products") }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-col>
      </template>

      <v-col
        cols="12"
        v-if="!currentTurn && !loading"
        class="d-flex flex-column justify-center align-center pa-10 text-center"
      >
        <v-card class="pa-5">
          <v-icon
            size="40"
            color="blue"
            class="blue lighten-5 pa-2 rounded-pill"
            >mdi-store-plus</v-icon
          >
          <v-card-title class="grey--text text--darken-1"
            >No hay un turno disponible en este momento.</v-card-title
          >
          <v-card-subtitle
            >Inicia un nuevo turno haciendo clic en el botón
            inferior.</v-card-subtitle
          >
          <v-card-actions>
            <v-btn
              color="primary"
              class="mx-auto"
              @click="modalOpenTurn"
              :title="$t('open_turn')"
              >{{ $t("open_turn") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <SearchProducts
        v-if="!showCartMobile"
        @select:product="manageProductSelected"
        @select:product-variant="manageProductVariantSelected"
        @select:product-batch="manageProductSelected"
        :keyReload="keyReloadProducts"
        :is_mobile="$store.getters.mobile"
        :aspect_ratio="$store.getters.aspect_ratio"
        :priceList="currentPriceList"
        ref="searchProd"
      ></SearchProducts>

      <CartSection
        @edit:item="editItemLine"
        @prescription:item="prescriptionEditItemLine"
        :is_mobile="$store.getters.mobile"
        :aspect_ratio="$store.getters.aspect_ratio" v-if="calculateShowCartMobile"
      >
      </CartSection>
    </v-row>

    <Install
      v-if="user"
      :open="openInstall"
      @cancel="cancelEditProduct()"
      @installed="installedOk"
    ></Install>

    <OpenTurn
      :open="openTurn"
      @cancel="openTurn = false"
      @opened="turnOpened"
      v-if="!loading"
    ></OpenTurn>

    <CartSectionProductDetail
      :dialog="modalItem"
      :key="key"
      @selectedOptions="selectedOptions"
      :currentProduct="currentProduct"
      :itemLine="currentItemLine"
      @cancel="closeItemDetail"
      :priceList="currentPriceList"
    >
    </CartSectionProductDetail>

    <CartSectionProductBatchDetail
      :dialog="modalBatchItem"
      :key="keyBatch"
      @selectedOptions="selectedOptions"
      :currentProduct="currentProduct"
      :itemLine="currentItemLine"
      @cancel="closeItemDetail"
      :priceList="currentPriceList"
    >
    </CartSectionProductBatchDetail>

    <CartWaiterOrders
      :dialog="modalCartWaitersOrders"
      @cancel="modalCartWaitersOrders = false"
      @close="modalCartWaitersOrders = false"
    ></CartWaiterOrders>

    <CartPendingOrdersRemote
      :dialog="modalCartPendingOrders"
      @cancel="modalCartPendingOrders = false"
      ref="cmpCartPendingOrders"
      @close="modalCartPendingOrders = false"
    ></CartPendingOrdersRemote>

    <CartTables
      :dialog="modalTables"
      :order="$pos.getCurrentOrder()"
      @cancel="modalTables = false"
      @close="modalTables = false"
    ></CartTables>

    <CartPrescription
      :dialog="modalPrescription"
      :key="keyPrescription"
      :currentItem="currentItemLine"
      @cancel="modalPrescription = false"
      @selectedPrescription="selectedPrescription"
    ></CartPrescription>

    <v-dialog v-model="showWarningOrderWithoutSave" width="350">
      <v-card>
        <v-card-text class="pt-4 text-center">
          Existe una orden en curso, debe guardarla o anularla antes de crear
          una nueva
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            @click="showWarningOrderWithoutSave = false"
            color="primary"
            outlined
            block
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--CartPendingOrders :dialog="modalCartPendingOrders" @cancel="modalCartPendingOrders = false"  :key="'cart_'+$store.getters.orderCreated"
      @close="modalCartPendingOrders = false"></CartPendingOrders-->
  </div>
</template>

<script>
import SearchProducts from "./SearchProducts";
import Install from "./Install";
import OpenTurn from "./OpenTurn";
import CartTables from "./CartTables";
import CartSection from "./CartSection";
import CartSectionProductDetail from "./CartSectionProductDetail.vue";
import CartSectionProductBatchDetail from "./CartSectionProductBatchDetail.vue";
import CartPendingOrdersRemote from "./CartPendingOrdersRemote";
import SupportChat from "../../../layouts/header_components/SupportChat.vue";
//import CartPendingOrders from "./CartPendingOrders";
import CartWaiterOrders from "./CartWaiterOrders";
import CartPrescription from "./CartPrescription.vue";
import io from "socket.io-client";
import { deleteEvent, listenEvents } from "@/plugins/db-firestore";

export default {
  name: "HomePosIndex",
  components: {
    SearchProducts,
    CartSection,
    CartSectionProductDetail,
    CartSectionProductBatchDetail,
    //CartPendingOrders,
    CartPendingOrdersRemote,
    CartWaiterOrders,
    CartTables,
    CartPrescription,
    Install,
    OpenTurn,
    SupportChat,
  },
  data: () => ({
    openInstall: false,
    modalCartWaitersOrders: false,
    ws: null, //websocket local, funciona sin internet, para validar que nose conecten varias ventanas del pos
    socket: null, //websocket nube, para validar si esta abierta la caja y otras validaciones
    openTurn: false,
    installed: false,
    key: 0,
    keyBatch: 1000,
    keyPrescription: 100000,
    products: [],
    currentPriceList: null,
    priceLists: [],
    modalItem: false,
    modalBatchItem: false,
    modalCartPendingOrders: false,
    modalPrescription: false,
    currentProduct: false,
    currentItemLine: null,
    keyReloadProducts: 0,
    databaseLoaded: false,
    totalPendingOrders: 0,
    app_version: null,
    turn: null,
    loading: false,
    showWarningOrderWithoutSave: false,
    showCartMobile: false,
    modalTables: false,
    localVersion: '',
  }),
  computed: {
    currentTurn() {
      return this.$store.getters.turn;
    },
    user() {
      return this.$store.getters.user;
    },
    calculateShowCartMobile(){
      if(this.$store.getters.mobile){
        return this.showCartMobile;
      }else{
        return true;
      }
    },
  },
  watch: {
    "$store.getters.orderCreated": function () {
      this.loadGeneralInfo();
    },
    "$store.getters.online": function () {
      if (this.$store.getters.online) {
        this.$pos.sincronizeSync();
      }
    },
    "$store.state.db.loaded": async function  () {
      this.databaseLoaded = true;
      console.log("[HOME]loaded bd.............");
      /* setTimeout(() => {
        this.installedEvent();

      }, 1000);*/
      await this.installedEvent();
      this.loadTurn();
      await this.validateVersionApp();
    },
    currentTurn: function (val) {
      if (val) {
        this.openTurn = false;
      }
    },
  },
  mounted() {
    setTimeout(async () => {
      this.connectWebSocket();
      //this.loadTurn();
    }, 1200);
    this.localVersion =  localStorage.getItem("app_version");
    if (this.user) {
      this.installedEvent();

      this.loadTurn();
    }
  },
  methods: {
    
    windowClose() {
      window.close();
    },
    async validateVersionApp() {
      let actualVersion = localStorage.getItem("app_version");
      let nubeVersion = this.$http.getAppVersion();
      if (!actualVersion || actualVersion !== nubeVersion) {
        localStorage.setItem("app_version", nubeVersion);
        await this.updateInfo();
      }
    },
    loadTurn() {
      this.loading = true;
      const installation = this.$store.getters.installation;
      if (!installation) {
        return;
      }
      const cash_register = installation.cash_id;
      this.$http
        .get(
          "api/v1/pos/current-turn/" +
            this.$store.getters.installation.subsidiary_id,
          { cash_register: cash_register }
        )
        .then((response) => {
          if (response.success) {
            this.$store.commit("setTurn", response.turn);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    connectWebSocket() {
      // Conectar al servidor WebSocket
      this.ws = new WebSocket("wss://localhost/websocket");
      // Evento para cuando la conexión se establece
      this.ws.onopen = () => {
        console.log("Conexión WebSocket abierta");
      };
      // Evento para manejar mensajes recibidos
      this.ws.onmessage = (event) => {
        console.log("Mensaje recibido:", event.data);
        let mess = JSON.parse(event.data);
        //this.message = event.data;
        if (mess.con > 1) {
          //this.nubeempresaIsOpen = true;
        } else {
          //this.nubeempresaIsOpen = false;
        }
      };
      // Evento para manejar errores
      this.ws.onerror = (error) => {
        console.error("Error en WebSocket:", error);
      };
      // Evento para cuando la conexión se cierra
      this.ws.onclose = (event) => {
        console.log("Conexión WebSocket cerrada", event);
      };
    },
    disconnectWebSocket() {
      if (this.ws) {
        this.ws.close();
      }
    },

    changePriceList() {},
    async testPrint() {
      const template = await this.$db.getTemplate(2);
      this.$store.dispatch("printData", {
        tmpl: template.body,
        info: { name: "" },
      });
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    async loadGeneralInfo() {
      this.totalPendingOrders = await this.$pos.getTotalOpenedOrders();
    },
    closeItemDetail() {
      this.modalItem = false;
      this.modalBatchItem = false;
      this.key++;
      this.keyBatch++;
    },
    anullCurrentOrderClick() {
      this.showWarningOrderWithoutSave = false;
      //this.anullCurrentOrder = true;
    },
    newOrder() {
      if (this.$pos.getCurrentOrder().isEmpty()) {
        this.$pos.createOrder();
      } else {
        //this.anullCurrentOrder = true;
        this.showWarningOrderWithoutSave = true;
      }
    },
    modalOpenTurn() {
      this.openTurn = true;
    },
    launchMenuPos(evt) {
      return false;
    },
    async checkTurn() {
      this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;
      if (!this.$store.getters.turn) {
        this.modalOpenTurn();
      }
    },
    async turnOpened(turn) {
      //let turn = this.$store.getters.turn;
      this.openTurn = false;

      /*this.$pos
        .getCurrentOrder()
        .setTurnLocalReference(turn.local_reference);
      this.$pos.getCurrentOrder().setBillCashRegisterTurnId(turn.id);
      this.$pos.notifyChangeInOrder();*/
    },
    installedOk() {
      setTimeout(() => {
        //
        this.installedEvent();
        // window.location.href = "/pos";
      }, 1);
      //
    },
    async installedEvent() {
      if (
        this.$store.state &&
        this.$store.state.db &&
        this.$store.state.db.loaded
      ) {
        const installed = await this.$db.itsInstalled();
        if (installed) {
          let installation = this.$store.getters.installation;
          if (!installation) {
            installation = await this.$db.adapter.getInstallation();
            this.$store.dispatch("setInstallation", installation);
          }
          this.$pos.priceLists = await this.$db.getPriceLists();
          this.currentPriceList = this.getCurrentList();
          this.installed = true;
          this.openInstall = false;
          this.loadGeneralInfo();
          this.openCloudSocket();
          await this.checkTurn();
          this.key++;
          this.keyBatch++;
          this.keyReloadProducts++;

          if (
            this.$store.getters.getGeneralValue("gen_sales_manage_screens") ||
            this.$store.getters.getGeneralValue("gen_sales_manage_waiters") ||
            this.$store.getters.getGeneralValue("gen_sales_manage_delivery")
          ) {
            console.log("listen changes waiters....");
            listenEvents(
              installation.subsidiary_id,
              (doc, idEvent) => {
                console.log("order received", doc);
                this.alertOrderToReceive(doc.order, idEvent);
              },
              null
            );
          }
        } else {
          this.openInstall = true;
        }
      }
      //this.load();
    },
    alertOrderToReceive(id, idEvent) {
      // se modifica para solo mostrar aviso de ordenes por recibir
      /*let installation = this.$store.getters.installation;
      this.$http.get("api/v1/orders-to-receive", { id: id, event: idEvent, 'subsidiary': [installation.subsidiary_id] }).then(async (response) => {
        if (response.success && response.items.length > 0) {
          //await this.$db.updateOrders(response.items);
          this.$store.commit("setOrderReceived", true);

        }
      });*/
      //elimina el evento de firestore
      let installation = this.$store.getters.installation;
      deleteEvent(installation.subsidiary_id, idEvent);
      this.$store.commit("setOrderReceived", true);
    },
    openCloudSocket() {
      /*  this.socket = io("ws://localhost:3000"); // Asegúrate de reemplazar esto con la URL de tu servidor

  // Escuchar eventos
  this.socket.on("connect", () => {
  });

  this.socket.on("mensaje", (data) => {
  });*/
    },
    getCurrentList() {
      for (const ls of this.$pos.priceLists) {
        if (ls.is_general == true) {
          return ls;
        }
      }
      return this.$pos.priceLists[0];
    },
    cancelEditProduct() {
      this.currentProduct = null;
      this.modalItem = false;
      this.modalBatchItem = false;
    },
    //6545353890355
    async manageProductVariantSelected(variant) {
      let product = await this.$db.getProduct(variant.inv_product_id);
      this.$pos.addItem(
        product,
        {
          id: variant.id,
          name: variant.name,
          reference: variant.reference,
          bar_code: variant.bar_code,
          has_batch: variant.has_batch,
        },
        1,
        "",
        null,
        null,
        -1
      );
      this.$store.commit("setOrderChanged", true);
    },
    async focusFieldQuantityScale() {
      let installation = this.$store.getters.installation;
      let modePrinter = "browser";
      if (installation) {
        const cashRegister = await this.$db.getCashRegister(
          installation.cash_id
        );
        if (cashRegister) {
          if (cashRegister.config) {
            if (cashRegister.config.pos_active_print_agent) {
              modePrinter = "agent";
            }
          }
        }
      }
      if (modePrinter == "agent") {
      } else {
        setTimeout(() => {
          const element = document.getElementById("quantity-field");
          if (element !== null) {
            element.focus();
            element.select();
          }
        }, 325);
      }
    },
    // adiciona los productos seleccionados, toma siempre la current list
    async manageProductSelected(product) {
      this.currentProduct = null;
      this.currentItemLine = null;

      if (product.has_batch) {
        this.keyBatch++;
        this.currentProduct = product;
        this.modalBatchItem = true;
      } else {
        if (product.type == "variant") {
          this.key++;
          this.currentProduct = product;
          this.modalItem = true;
        } else {
          if (product.sale_options || product.is_scale == true) {
            this.key++;
            this.currentProduct = product;
            this.modalItem = true;
            await this.focusFieldQuantityScale();
          } else {
            this.$pos.addItem(
              product,
              null,
              null,
              null,
              null,
              null,
              -1,
              this.currentPriceList.id
            );
          }
        }
      }
    },
    async editItemLine(itemLine) {
      this.currentProduct = null;
      this.currentItemLine = itemLine;
      if (itemLine.product.has_batch) {
        this.keyBatch++;
        this.modalBatchItem = true;
      } else {
        this.key++;
        this.modalItem = true;
      }
      if (itemLine.product && itemLine.product.is_scale) {
        await this.focusFieldQuantityScale();
      }
    },
    async prescriptionEditItemLine(itemLine) {
      this.currentProduct = null;
      this.currentItemLine = itemLine;
      this.keyPrescription++;
      this.modalPrescription = true;
    },

    async selectedPrescription(data_doctor) {
      const product = await this.$db.getProduct(
        this.currentItemLine.product.id
      );
      await this.$pos.updateItem(
        this.currentItemLine,
        product,
        this.currentItemLine.product_variant,
        this.currentItemLine.quantity,
        this.currentItemLine.note,
        this.currentItemLine.options,
        this.currentItemLine.discount_amount,
        this.currentItemLine.price_amount,
        this.currentItemLine.price_list_id,
        this.currentItemLine.batch,
        data_doctor
      );

      this.$store.commit("setOrderChanged", true);
      this.modalPrescription = false;
    },
    async selectedOptions(options) {
      if (options.options) {
        for (let i in options.options) {
          if (
            options.options[i] &&
            options.options[i].value &&
            Array.isArray(options.options[i].value) &&
            options.options[i].value.length == 0
          ) {
            delete options.options[i];
          }
        }
      }

      if (options.itemLine) {
        const product = await this.$db.getProduct(options.itemLine.product.id);
        let data_doctor = null;
        if (options.data_doctor) {
          data_doctor = options.data_doctor;
        }
        await this.$pos.updateItem(
          options.itemLine,
          product,
          options.variant,
          options.quantity,
          options.note,
          options.options,
          options.discount,
          options.new_price,
          options.bill_price_list_id,
          options.batch,
          data_doctor
        );
      } else {
        this.$pos.addItem(
          this.currentProduct,
          options.variant,
          options.quantity,
          options.note,
          options.options,
          options.discount,
          options.new_price,
          options.bill_price_list_id,
          options.batch,
          null
        );
      }
      this.modalItem = false;
      this.modalBatchItem = false;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    openModalCartPendingOrders() {
      this.modalCartPendingOrders = true;
      setTimeout(() => {
        this.$refs.cmpCartPendingOrders.getPendingOrders();
      }, 500);
    },
    openModalCartWaiterOrders() {
      this.modalCartWaitersOrders = true;
    },
    async updateInfo() {
      //await this.$db.updateInfo(this);
      //this.store.commit('setLoading', true);
      await this.$pos.sincronizeSync();
      const syncroRemote = await this.$pos.syncroRemoteToLocal();
      this.$refs.searchProd.updateProducts({}, 20, "name");
      this.keyReloadProducts++;
      
      this.$store.commit("setLoading", true);
      if (syncroRemote) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    },
    viewCart(view) {
      if (this.currentTurn) {
        if (view == "search_products") {
          if (this.$store.getters.mobile) {
            if (!this.showCartMobile) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
        if (view == "cart") {
          if (this.$store.getters.mobile) {
            if (this.showCartMobile) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    viewCartMobile(value) {
      this.showCartMobile = value;
    },
  },
};
</script>

<style>
body,
html {
  overflow: hidden;
}

@media only screen and (max-width: 981px) {
  body,
  html {
    overflow: visible !important;
  }
}

.desktop-height {
  height: calc(100vh - 65px) !important;
}

.mobile-height {
  height: 100vh !important;
}

.price_list .v-text-field fieldset,
.price_list .v-text-field .v-input__control,
.price_list .v-text-field--single-line .v-select__selections,
.price_list .v-select__selection,
.price_list .v-icon {
  color: #969696 !important;
}

.fixed-bar {
  position: fixed;
  top: 70px;
  min-height: 70px;
  width: 100%;
  z-index: 2;
  box-shadow: none !important;
}

.cart_mobile {
  margin-top: 45px !important;
}

.account-menu .v-list .v-list-item--active {
  color: transparent !important;
}

.balance-contrast {
  background: rgba(0, 0, 0, 0.01);
  box-shadow: rgba(0, 0, 0, 0.01) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
}
</style>
