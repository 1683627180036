<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card v-if="product" class="pb-4">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ product.name }}
            <v-chip color="default" filter label>{{ product.reference }}</v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="$emit('cancel')" :title="$t('Cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text style="max-height: 60vh; overflow-y: auto">
          <v-row class="pa-2 fill-height">
            <v-col lg="3" class="pt-0 text-center text-lg-left">
              <v-list-item-avatar tile size="110" class="rounded-lg" color="grey">
                <v-img height="100%" :src="getPathProduct(product)"></v-img>
              </v-list-item-avatar>
            </v-col>
            <v-col lg="9" cols="12" class="pt-2 pb-0">
              <ng-select-options v-if="$pos && $pos.priceLists" v-model="currentPriceList" :items="$pos.priceLists"
                @change="changePriceList" :clearable="false" :flat="false" :filled="false" dense :outlined="true"
                :full-width="true"></ng-select-options>

              <v-select v-model="discount" :items="discountRates" :label="$t('discounts')" @change="changePriceList"
                dense outlined>
              </v-select>

              <div class="d-flex justify-space-between">
                <ng-number-keyboard v-model="quantity" :label="$t('quantity')" rules="required" :filled="false" dense
                  show-required="true" id="quantity-field"></ng-number-keyboard>
                <v-btn small color="red" dark fab class="mx-2" @click="minus" :title="$t('decrease')">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn small color="success" dark fab @click="quantity++" :title="$t('increase')">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
         
  
            <template v-if="!variantsBatch">
              <template v-for="itemBatch in batchItems">
                <v-row dense class="mt-2 py-0 ">
                  <v-col cols="3" class="py-0 text-center">
                    <span class="black--text font-weight-bold text-body-2">{{ $t('Lote') }}:</span>
                    <span v-if="itemBatch.batch" class="black--text text-caption"><v-chip color="primary" filter label
                        class="ml-1" small>{{
                          itemBatch.batch }}</v-chip></span>
                  </v-col>

                  <v-col cols="3" class="py-0">
                    <span class="black--text font-weight-bold text-body-2">{{ $t('Vence en') }}:</span>
                    <span class="black--text text-caption"><v-chip color="default" filter label class="ml-1" small>{{
                      itemBatch.expiration_date }}</v-chip></span>
                  </v-col>
                  <v-col cols="3" class="py-0 text-center">
                    <span class="black--text font-weight-bold text-body-2">{{ $t('Existencias') }}:</span>
                    <span class="black--text text-caption"><v-chip color="default" filter label class="ml-1" small>{{
                      itemBatch.quantity
                        }}</v-chip></span>
                  </v-col>
                  <v-col cols="3" class="py-0 text-center">
                    <v-btn small color="primary" @click="addItem(itemBatch)" :disabled="invalid">Seleccionar</v-btn>
                    </v-col>
                </v-row>
              </template>
            </template>
            <template v-else>
              <div>
                <v-chip v-for="(option, index) in variants[0].options" :key="index" >
                  {{ option.name }}: {{ $filters.currency( getPriceItem(option.id)) }}
                </v-chip>
              </div>
              <template v-for="itemBatch in batchItems">

                <v-col cols="12" v-for="(vr, index) in variantsBatch[itemBatch.id]" :key="'div' + itemBatch.id"
                  class="pt-4">

                  <v-row dense class="mt-2 py-0 ">
                    <v-col cols="4" class="py-0 text-center">
                      <span class="black--text font-weight-bold text-body-2">{{ $t('Lote') }}:</span>
                      <span v-if="itemBatch.batch" class="black--text text-caption"><v-chip color="primary" filter label
                          class="ml-1" small>{{
                            itemBatch.batch }}</v-chip></span>
                    </v-col>

                    <v-col cols="4" class="py-0">
                      <span class="black--text font-weight-bold text-body-2">{{ $t('Vence en') }}:</span>
                      <span class="black--text text-caption"><v-chip color="default" filter label class="ml-1" small>{{
                        itemBatch.expiration_date }}</v-chip></span>
                    </v-col>
                    <v-col cols="4" class="py-0 text-center">
                      <span class="black--text font-weight-bold text-body-2">{{ $t('Existencias') }}:</span>
                      <span class="black--text text-caption"><v-chip color="default" filter label class="ml-1" small>{{
                        itemBatch.quantity
                          }}</v-chip></span>
                    </v-col>
                    <v-col cols="12" class="pt-1 d-flex justify-center mt-2">
                      <!--v-btn-toggle v-model="variantsItem"   group-->
                        <template v-for="(opt, i) in vr.options">
                          <!-- :value="itemBatch.batch + '-' + opt.id" -->
                          <v-btn color="primary" class="white--text mr-2"
                             @click="selectVariant(opt, vr, itemBatch)" :disabled="invalid"
                          >
                            {{
                              opt.name
                            }} 
                          </v-btn>
                        </template>


                      <!--/v-btn-toggle-->
                     
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </template>
            <!--v-col cols="12" class="py-0 mt-3">
              <ng-textarea-keyboard v-model="note" :label="$t('note')" :filled="false" dense show-required="true"
                rows="4"></ng-textarea-keyboard>
            </v-col-->


          </v-row>
        </v-card-text>

       

        <!--v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark outlined color="primary" @click="$emit('cancel')" :title="$t('Cancel')">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="update" :disabled="invalid" :title="$t('accept')">
            {{ $t("accept") }}
          </v-btn>
        </v-card-actions-->
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<style scoped>
.v-item--active {
  border: 1px solid rgb(0, 100, 150) !important;
  font-size: 1.2em !important;
  font-weight: bold;
}

.cart-selection-variant-option {
  border: 1px solid #ccc;
  max-width: 25%;
}
</style>
<script>
import CartSectionProductSaleOptions from "./CartSectionProductSaleOptions";
export default {
  name: "CartSectionProductBatchDetail",
  components: { CartSectionProductSaleOptions },
  props: ["itemLine", "currentProduct", "open", "dialog", "priceList"],
  data: () => ({
    //prices: null
    //isVisible: false
    discountRates: [
      0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
      95,
    ],
    discount: 0,
    quantity: 1,
    options: null,
    optionsItem: {},
    variants: null,
    variantsBatch: null,
    variantsItem: {},
    variant: null,
    productVariant: null,
    key: 0,
    note: "",
    product: null,
    price: 0,
    currentPriceList: null,
    changePriceListFromForm: false,
    priceIsChange: false,
    cashRegister: null,
    enablePriceChange: false,
    batchItems: [

    ]
  }),
  watch: {
    'dialog': {
      deep: true,
      immediate: true,
      handler() {
        console.log(this.dialog);
        if (this.dialog) {
          //  setTimeout(() => {
          this.loadBatch();
          //  }, 1000)
        }
      },
    }
  },
  async mounted() {
    this.clean();
    await this.loadConfig();


    if (this.priceList) {
      this.currentPriceList = {
        id: this.priceList.id,
        name: this.priceList.name,
      };
    }
    if (this.itemLine && this.itemLine.price_list_id) {
      for (const priceList of this.$pos.priceLists) {
        if (this.itemLine.price_list_id == priceList.id) {
          this.currentPriceList = { id: priceList.id, name: priceList.name };
          break;
        }
      }
    }
    if (this.itemLine) {
      if (this.itemLine.percentage_discount) {
        this.discount = this.itemLine.percentage_discount;
      }
      if (this.itemLine.unit_price) {
        this.price = this.itemLine.unit_price;
      }
    } else {
      if (this.product) {
        this.getProductPrice();
      }
    }
    this.setCashRegister();
  },

  methods: {
    getPriceItem(optionId) {
      let productId = null;
      if (this.currentProduct) {
        productId = this.currentProduct.id;
      }else{
        productId = this.itemLine.product.id
      }
      let product = this.$db.getProduct(productId);
      let prodVariant = this.$db.searchVariant(this.product.id, [optionId]);

      return this.$pos.getSalePrice(
            this.product,
            this.discount,
            this.getPriceListId(),
            prodVariant.id
          );

    },
    selectVariant(opt, vr, batch) {


      this.$nextTick(() => {
        this.variantsItem = batch.batch + '-' + opt.id;

        const options = [opt.id];
        let product_id = null;
        if (this.itemLine) {
          product_id = this.itemLine.product.id
        } else {
          product_id = this.currentProduct.id
        }

        this.productVariant = this.$db.searchVariant(product_id, options);
        this.update();
      })



    },
    loadBatch() {
      let installation = this.$store.getters.installation;
      if (!installation) return;

      let productId = null;
      if (this.currentProduct) {
        productId = this.currentProduct.id;
      }
      if (this.itemLine) {
        productId = this.itemLine.product.id
      }

      this.$http.get("api/v1/stock-batch", { product: [productId], active: true, subsidiary: [installation.subsidiary_id] }, true).then((response) => {
        if (response.items.length > 0) {
          this.batchItems = response.items;
          if (this.variants) {
            this.variantsBatch = {};
            for (const item of this.batchItems) {
              this.variantsBatch[item.id] = Object.assign({}, this.variants);
            }
          }
        }
      })
    },

    getProductPrice() {
      if (this.itemLine && !this.changePriceListFromForm) {
        this.price = this.$pos.getSalePrice(
          this.product,
          this.discount,
          this.getPriceListId(),
          this.productVariant?.id
        );
        if (this.discount) {
          return this.$pos.getSalePrice(
            this.product,
            this.discount,
            this.getPriceListId(),
            this.productVariant?.id
          );
        } else {
          return this.price;
        }
      } else {
        this.price = this.$pos.getSalePrice(
          this.product,
          this.discount,
          this.getPriceListId(),
          this.productVariant?.id
        );
        return this.price; //this.$pos.getSalePrice(this.product, this.discount, this.getPriceListId())
      }
    },
    changePriceList() {
      this.changePriceListFromForm = true;
      if (this.currentPriceList) {
        //return this.currentPriceList.id
        this.price = this.$pos.getSalePrice(
          this.product,
          0,
          this.getPriceListId(),
          this.productVariant?.id
        );
      }
    },
    getPriceListId() {
      if (this.currentPriceList) {
        return this.currentPriceList.id;
      }
      return null;
    },
    clean() {
      this.key++;
      this.product = this.currentProduct;
      this.variants = null;
      this.variantsItem = null;
      this.variant = null;
      this.options = null;
      this.optionsItem = {};
      this.note = "";
      this.loadConfig();
      this.priceIsChange = false;
      this.key++;
    },
    minus() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },



    async loadConfig() {
      if (this.itemLine) {
        this.quantity = this.itemLine.quantity;
        this.product = await this.$db.getProduct(this.itemLine.product.id);
        this.note = this.itemLine.note;
      }

      if (this.product.type == "variant") {
        await this.loadVariants();
      }

    },

    addItem(itemBatch){
      this.variantsItem = itemBatch.batch+'-'+itemBatch.batch ;
      this.update();
    },
    async update() {

      console.log( this.itemLine, 'iteldoct');

      //console.log(this.productVariant, "update product detail....");
      let opts = {
        itemLine: this.itemLine,
        variant: this.productVariant,
        quantity: this.quantity,
        note: this.note,
        options: this.optionsItem,
        discount: this.discount,
        new_price: -1,
        bill_price_list_id: this.currentPriceList.id,
      };
      if (this.priceIsChange) {
        opts.new_price = this.price;
      } else {
        let price = this.$pos.getSalePrice(
          this.product,
          0,
          this.getPriceListId(),
          this.productVariant?.id
        );
        opts.new_price = price;
      }
      if (this.variantsItem) {
        let parts = this.variantsItem.split('-');
        opts.batch = parts[0];
        this.variantsItem = '';
      }
      if(this.itemLine && this.itemLine.data_doctor){
        opts.data_doctor = this.itemLine.data_doctor;
      }
      this.$emit("selectedOptions", opts);
    },
    getPathProduct(item) {
      if (item) {
        return this.$http.getBaseUrlImagesProducts() + item.image;
      }
      return "@/assets/product-blank.png";
    },
    getProductsByCategory(catId) {
      const prds = this.$db.searchProducts(
        {
          category_id: { $eq: catId },
        },
        30,
        "name"
      );
      return prds;
    },

    changePrice() {
      this.priceIsChange = true;
    },
    async loadVariants() {
      let allVariants = await this.$db.getVariants();

      const variantsProduct = [];
      this.variantsItem = null; //variantes seleccionadas
      let currentVariant = null;
      if (
        this.itemLine &&
        this.itemLine.product_variant &&
        this.itemLine.product_variant.id
      ) {
        currentVariant = await this.$db.getProductVariants(
          this.itemLine.product_variant.id
        );
      }
      // se optienen la informacion de las opciones posibles configuradas para el producto de cada variante
      // si itemLine esta seteado se preseleccionan las opciones de variantes correspondientes
      for (const v of this.product.variants.variants) {
        for (const variant of allVariants) {
          if (v.id == variant.id) {
            const options = [];
            for (const opt of this.product.variants["v-" + v.id]) {
              const idx = variant.options.findIndex((o) => o.id == opt.id);
              if (idx >= 0) {
                const option = variant.options[idx];
                option.selected = false;

                if (
                  currentVariant &&
                  currentVariant.inv_variant_option_ids &&
                  currentVariant.inv_variant_option_ids.indexOf(opt.id) >= 0
                ) {
                  option.selected = true;
                  //this.variantsItem[v.id] = opt.id;
                }
                options.push(option);
                if (currentVariant && currentVariant.length > 0) {
                  /*for (const vari of currentVariant) {
                    if (
                      vari &&
                      vari.inv_variant_option_ids &&
                      vari.inv_variant_option_ids.indexOf(opt) >= 0
                    ) {
                      this.variantsItem[v.id] = opt;
                    }
                  }*/
                }
              }
            }
            variantsProduct.push({
              id: variant.id,
              name: variant.name,
              is_colour: variant.is_colour,
              options: options.sort(function (a, b) {
                return a.name.localeCompare(b.name);
              }),
            });
          }
        }
      }
      this.variants = variantsProduct.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.key++;
    },
    async setCashRegister() {
      let installation = this.$store.getters.installation;

      if (installation && !this.cashRegister) {
        this.cashRegister = await this.$db.getCashRegister(
          installation.cash_id
        );
      }

      this.viewPriceChange();
    },
    async viewPriceChange() {
      this.enablePriceChange = false;
      if (this.cashRegister) {
        if (this.cashRegister.config) {
          if (
            this.cashRegister.config.pos_active_price_change &&
            this.itemLine
          ) {
            this.enablePriceChange =
              this.cashRegister.config.pos_active_price_change;
          }
        }
      }
    },
  },
};
</script>