<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card v-if="config" :key="key">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("prescription") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="cancel" :title="$t('Cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>
          <v-row class="px-2 py-4 mb-3 pb-0">
            <v-col
              cols="12"
              lg="9"
              class="pb-0"
            >
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="professional_card"
                :label="$t('professional_card')"
                @keyup.enter="searchDoctor"
                @clear="clearSearch"
                hide-details
              ></ng-text-keyboard>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="mt-0"
            >
              <v-btn
                block
                color="primary"
                :disabled="!professional_card"
                @click="searchDoctor"
                :title="$t('Search')"
              >
                <v-icon>mdi-magnify</v-icon> {{ $t("Search") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />



          <v-row v-if="searched" class="pa-2 my-0 mt-3 pb-0">
            <!-- {{item}} -->
            <v-col cols="12" sm="6" class="py-0">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.first_name"
                :label="$t('first_name')"
                :rules="{ required: true }"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.last_name"
                :label="$t('last_name')"
                :rules="{ required: true }"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.professional_card"
                :label="$t('professional_card')"
                :rules="{ required: true }"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-email-keyboard
                :filled="false"
                :dense="true"
                v-model="item.email"
                :label="$t('email')"
              ></ng-email-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.address"
                :label="$t('address')"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-phone-keyboard
                :filled="false"
                :dense="true"
                v-model="item.phone"
                :label="$t('phone')"
              ></ng-phone-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-select
                v-model="item.type_identification"
                label="type_identification"
                url="api/v1/list/type-identification"
              ></ng-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-text
                v-model="item.identification"
                label="identification"
              ></ng-text>
            </v-col>            
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="grey"
            outlined
            @click="cancel"
            :title="$t('Cancel')"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            v-if="searched"
            color="primary"
            :disabled="invalid"
            @click="selected"
            :title="$t('select_doctor')"
          >
            {{ $t("select_doctor") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
const DEFAULT_ITEM = {
  id: "",
  name: "",
  last_name: "",
  email: "",
  professional_card: "",
  phone: "",
  address: "",
  type_identification: null,
  identification: null
};
export default {
  components: {},
  name: "CartCustomer",
  data: () => ({
    searched: false,
    key: 0,
    item: JSON.parse(JSON.stringify(DEFAULT_ITEM)),
    config: {
      first_name: {
        name: "first_name",
        label: "Nombres",
        required: true,
        validate: ["required"],
      },
      last_name: {
        name: "last_name",
        label: "Apellidos",
        required: true,
        validate: ["required"],
      },
      identification: {
        name: "identification",
        label: "Numero Identificación",
        required: false,
        validate: [],
      },
      email: { name: "email", label: "Email", required: false, validate: [] },
      phone: {
        name: "phone",
        label: "Celular",
        required: true,
        validate: ["required"],
      },
      address: {
        name: "address",
        label: "Dirección",
        required: false,
        validate: [],
      },
    },
    order: null,
    professional_card: "",
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      default: null,
    }
  },
  //props: ['customer'],
  watch: {
    dialog(newVal, oldVal) {
      //this.setDefaultClient();
      this.setDefaultClient();
    }
  },
  mounted() {
    this.setDefaultClient();
    /*if(this.customer){
      setTimeout(() => {


      this.item = JSON.parse(JSON.stringify(this.customer));
      this.searched = true;
      this.key++;
      }, 250)
    }*/
  },
  methods: {    
    selected() {
      const obj = JSON.parse(JSON.stringify(this.item));
      this.clear();
      this.clearSearch();
      this.searched = false;
      this.$emit("selectedPrescription", obj);
      this.key++;
    },
    cancel() {
      this.clear();
      this.clearSearch();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    searchDoctor() {
      if (this.professional_card) {
        if (this.professional_card != "" ) {
          let doc = this.professional_card;
          this.$http
            .get(
              "api/v1/list/doctor-by-professional-card",
              { professional_card: this.professional_card },
              true,
              false
            )
            .then((response) => {
              if (response.success) {
                if (response.items && response.items.length > 0) {
                  this.item = response.items[0];
                } else {
                  this.item = { professional_card: doc };
                }
              }
              this.searched = true;
            })
            .catch((e) => {
              this.searched = true;
            });
        }
      } 
    },
    clearSearch() {
      this.item = {};
      this.identification = "";
      this.searched = false;
    },
    setDefaultClient() {
      if (this.currentItem && this.currentItem.data_doctor) {
        this.searched = true;
        this.item = this.currentItem.data_doctor;
      } else {
        this.searched = false;
        this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
      }
    },
  },
};
</script>